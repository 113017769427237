import React from "react";
import styled from "styled-components";
import Footer from "../Footer";
import Navbar from "../Navbar";
function NAB1001() {
  return (
    <Container>
      <Navbar contactus={true} />
      <Main>
        <Inner>
          <Image>
            <img src="./photos/balerpng.png" alt="" />
          </Image>
          <Description>
            <Info>
              <h5>Machine Specification</h5>
              <ul>
                <li>The Whole baler runs 17KW with electric power</li>
                <li>Easy display Touch Electronic System</li>
                <li>
                  Speed adjustable all conveyers like bunker, feeding and
                  recycling conveyers
                </li>
                <li>Used Heavy duty chains for feeding conveyer</li>
                <li>German Company's Heavy duty gearbox for its long life</li>
                <li>All drive chains in Diamond</li>
                <li>Bottom conveyer for recycling waste material</li>
                <li>Air Compressor is provided to clean the machine</li>
                <li>
                  The Pressure for making bale can be increased or decreased
                  from display
                </li>
                <li>Machine Output : 40-45 bales per hour</li>
                <li>Machine Output : 4-5 MT per hour</li>
              </ul>
            </Info>
          </Description>
        </Inner>
      </Main>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  background-color: #042826;

  width: 100%;
  height: fit-content;
  min-height: 100vh;
  min-width: 100%;
  max-width: 1920px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    overflow-x: hidden;
    margin-top: 100px;
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (max-width: 1200px) {
    height: fit-content;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 15px;

  width: 65%;
  height: 80%;

  border-radius: 10px;

  @media only screen and (max-width: 1200px) {
    height: fit-content;
    align-items: center;
    justify-content: center;
    max-height: fit-content;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const Image = styled.div`
  width: 35%;

  img {
    width: 100%;
  }

  box-sizing: border-box;
  border-radius: 50px;

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;

const Description = styled.div`
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 70px;
  }
`;

const Info = styled.div`
  width: 100%;
  margin-bottom: -50px;
  h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;

    text-decoration-line: underline;

    color: #e5cf00;
  }

  ul {
    width: 100%;
    margin-top: 20px;

    color: #fff;
    li {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 1560px) {
    h5 {
      font-size: 20px !important;
      line-height: 30px !important;
    }

    li {
      font-size: 16px !important;
    }
  }
`;

export default NAB1001;
