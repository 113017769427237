import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import styled from "styled-components";
import Footer from "./Footer";
import Product from "./Products";
import Testimonials from "./Testimonials";
import GetInTouch from "./GetInTouch";
import { useRef } from "react";
import { scroller } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
function Home() {
  const ProductRef = useRef(null);
  const HeroRef = useRef(null);
  const TestimonialsRef = useRef(null);
  const GetInTouchRef = useRef(null);
  console.log(ProductRef);

  const ScrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Container>
      <Navbar
        ProductRef={ProductRef}
        TestimonialsRef={TestimonialsRef}
        ScrollToElement={ScrollToElement}
      />
      <Hero
        HeroRef={HeroRef}
        ProductRef={ProductRef}
        ScrollToElement={ScrollToElement}
      />
      <Product ProductRef={ProductRef} />
      <Testimonials TestimonialsRef={TestimonialsRef} />
      <GetInTouch GetInTouchRef={GetInTouchRef} />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100%;
  max-width: 2800px;
  margin: auto;
  z-index: 10;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default Home;
