import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
function Navbar({ ScrollToElement, ProductRef, contactus, TestimonialsRef }) {
  const navigate = useNavigate();

  const [changeColor, setChangeColor] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 70) {
      setChangeColor(true);
    } else {
      setChangeColor(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  return (
    <Container changeColor={changeColor}>
      <Logo onClick={() => navigate("/")}>
        {changeColor ? (
          <img src="./photos/final_1.svg" alt="" />
        ) : (
          <img src="./photos/final_2.svg" alt="" />
        )}
      </Logo>
      <Menu changeColor={changeColor}>
        {contactus ? (
          <p onClick={() => navigate("/")}>Home</p>
        ) : (
          <p onClick={() => ScrollToElement(ProductRef.current.className)}>
            Products
            <span>
              <svg
                width="10"
                height="7"
                viewBox="0 0 8 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.94 0.714233L4 4.05836L7.06 0.714233L8 1.74376L4 6.12471L0 1.74376L0.94 0.714233Z"
                  fill={changeColor ? "black" : "white"}
                />
              </svg>
            </span>
          </p>
        )}
        <p
          onClick={() => {
            navigate("/");
            ScrollToElement(TestimonialsRef.current.className);
          }}
        >
          Testimonials
        </p>
        <p>Download Brochure</p>
      </Menu>
      <Contact changeColor={changeColor} onClick={() => navigate("/contactus")}>
        <button>Contact Us</button>
      </Contact>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: 1000;
  width: 100%;
  max-width: 2800px;
  background-color: ${(props) => (props.changeColor ? "#fff" : "transparent")};
  visibility: ${(props) => (props.hideNavbar ? "hidden" : "visible")};
  transition: 0.4s all ease-in-out;

  /* -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999; */

  -webkit-box-shadow: ${(props) =>
    props.changeColor ? "0 8px 6px -6px #999" : "none"};
  -moz-box-shadow: ${(props) =>
    props.changeColor ? "0 8px 6px -6px #999" : "none"};
  box-shadow: ${(props) =>
    props.changeColor ? "0 8px 6px -6px #999" : "none"};
`;
const Logo = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 130px;
  }
`;
const Menu = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-evenly;
  width: 500px;
  p {
    color: ${(props) => (props.changeColor ? "#000" : "#fff")} !important;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    cursor: pointer;
    span {
      margin-left: 5px;
    }
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    /* background: rgba(255, 255, 255, 0.2); */
    background: ${(props) =>
      props.changeColor ? "#e5cf00" : "rgba(255, 255, 255, 0.2)"};
    backdrop-filter: blur(70px);
    /* Note: backdrop-filter has minimal browser support */
    cursor: pointer;
    border-radius: 15px;
    width: 130px;
    height: 35px;
    border: none;
    color: ${(props) => (props.changeColor ? "black" : "#fff")};
    font-size: 13px;
    transition: 0.2s all ease-in-out;
    &:hover {
      background-color: #ffec46;
      transition: 0.2s all ease-in-out;
      color: #000;
    }
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
export default Navbar;
