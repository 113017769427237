import React from "react";
import styled from "styled-components";
import "aos/dist/aos.css";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
function Testimonials({ TestimonialsRef }) {
  return (
    <Container
      data-aos="fade-up"
      data-aos-duration="2000"
      ref={TestimonialsRef}
    >
      <Tag>
        <h1>
          What People Say <span>About Us</span>
        </h1>
      </Tag>

      {/*  */}
      {/* <Testi>
          <TestiBox>
            <ProfilePic>
              <img src="./photos/user.png" alt="" />
            </ProfilePic>
            <Description>
              <Review>
                “On the Windows talking painted pasture yet its express parties
                use. Sure last upon he same as knew next. Of believed or
                diverted no.”
              </Review>
              <User>
                <h5>Dhruval Patel</h5>
                <p>Gujarat, India</p>
              </User>
            </Description>
          </TestiBox>
        </Testi> */}

      <Main>
        <Carousel
          prevIcon={false}
          nextIcon={false}
          prevLabel={false}
          nextLabel={false}
          style={{ width: "100%" }}
          fade
        >
          <Carousel.Item
            interval={2000}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Description>
                <ProfilePic>
                  <img src="./photos/user.png" alt="" />
                </ProfilePic>
                <Review>
                  “On the Windows talking painted pasture yet its express
                  parties use. Sure last upon he same as knew next. Of believed
                  or diverted no.”
                </Review>
                <User>
                  <h5>Dhruval Patel</h5>
                  <p>Gujarat, India</p>
                </User>
              </Description>
            </Box>
          </Carousel.Item>
          <Carousel.Item
            interval={2000}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Description>
                <ProfilePic>
                  <img src="./photos/user.png" alt="" />
                </ProfilePic>
                <Review>
                  “On the Windows talking painted pasture yet its express
                  parties use. Sure last upon he same as knew next. Of believed
                  or diverted no.”
                </Review>
                <User>
                  <h5>Pradhuman Patel</h5>
                  <p>Gujarat, India</p>
                </User>
              </Description>
            </Box>
          </Carousel.Item>
          <Carousel.Item
            interval={2000}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Description>
                <ProfilePic>
                  <img src="./photos/user.png" alt="" />
                </ProfilePic>
                <Review>
                  “On the Windows talking painted pasture yet its express
                  parties use. Sure last upon he same as knew next. Of believed
                  or diverted no.”
                </Review>
                <User>
                  <h5>Pushparaj Parmar</h5>
                  <p>Gujarat, India</p>
                </User>
              </Description>
            </Box>
          </Carousel.Item>
        </Carousel>
      </Main>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  padding-top: 160px;
  overflow-x: hidden;

  @media only screen and (max-width: 908px) {
    width: 100%;
    padding-top: 40px !important;
  }
`;
const Tag = styled.div`
  h1 {
    width: 400px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 55px;
    /* or 115% */

    text-align: center;

    color: #ffffff;

    @media only screen and (min-width: 1440px) {
      font-size: 45px;
      line-height: 60px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 25px;
      line-height: 45px;
      width: 280px;
    }
  }
  span {
    color: #e5cf00;
  }
`;

const Main = styled.div`
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  height: 550px;

  @media only screen and (max-width: 908px) {
    width: 100%;
  }
`;

const Box = styled.div`
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
`;

const ProfilePic = styled.div`
  position: absolute;

  top: -20px;
  left: -20px;
`;

const Description = styled.div`
  background-color: #fff;
  width: 90%;
  height: 200px;
  padding: 50px 30px;
  border-radius: 10px;

  position: relative;

  @media only screen and (max-width: 908px) {
    height: 250px;
  }
`;

const Review = styled.div`
  width: 90%;
  margin: auto;
`;

const User = styled.div`
  position: absolute;
  bottom: 0;
  right: 20px;

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 11px;
    margin-top: -5px;
  }
`;
export default Testimonials;
